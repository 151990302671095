import React from "react";
import { openPricingPage } from "utils/Utils";
import style from "./Style.module.css";
import useDesktopMediaQuery from "hooks/useDesktopMediaQuery";
import useHideNavAndAppBar from "hooks/useHideNavAndAppBar";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import { SUBSCRIPTION_PLAN } from "constants/account.constants";
import { featuresDescription, featuresHeading } from "./FeaturePage.constants";

const FeaturePage = ({ featureName, desktopImage, mobileImage, pageTitle }) => {
  const isDesktop = useDesktopMediaQuery();
  useHideNavAndAppBar(pageTitle);
  return (
    <div className={style.featureContainer}>
      <img
        src={isDesktop ? desktopImage : mobileImage}
        alt={featureName}
        className={style.featureImage}
      />
      <div className={style.featureTextContainer}>
        <div className={style.innerBox}>
          <LockRoundedIcon className={style.lockIcon} />
          <div className={style.heading}>{featuresHeading[featureName]}</div>
          <div className={`${style.description} mt-1`}>
            {featuresDescription[featureName]}
          </div>
          <div className={`${style.upgradeBtn} mt-3`} onClick={openPricingPage}>
            {`Upgrade to ${SUBSCRIPTION_PLAN.PRO}/${SUBSCRIPTION_PLAN.PREMIUM}`}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturePage;
