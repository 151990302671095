import React from "react";

export const featuresHeading = {
  custom_code: "Unlock Custom Code Functionality",
  creatives:
    "Use professional Creatives to drive better Ad Performance and Impactful Business decisions",
  certificates:
    "Unlock Professional Certificates for your users and allow them to showcase their competency",
  organisation:
    "Unlock Multi-fold Growth opportunities when working with a team",
  whatsapp_blast: (
    <span>
      <span className="butterscotch_yellow">40%</span> of our creators have
      unlocked <span className="butterscotch_yellow">5x</span> growth with
      WhatsApp Broadcast
    </span>
  ),
};

export const featuresDescription = {
  custom_code:
    "Unlock the Custom Code functionality to elevate your pages with your unique touch.",
  creatives: "Design & Download beautiful creatives for your social media",
  certificates:
    "Create & Share Certificates with your clients who have completed your courses or workshops",
  organisation:
    "Upgrade Now to assign different roles and access to different organisation members and increase your efficiency",
  whatsapp_blast:
    "Broadcast WhatsApp messages curated for your Leads and Customers, to increase your sells.",
};
